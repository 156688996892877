import { Controller } from 'stimulus';
import Rails from "@rails/ujs";



export default class extends Controller {
    
    static targets = ["settingsPopup", "consentFooter"]

    static values = { settings: Object }
   	
    initialize() {
      console.log("Consent controller");

      const cookieConsent = localStorage.getItem("cookie_consent_miroar");

      if(cookieConsent == null)
      {
         let consent = {"marketing": true, "analytics": true };
         localStorage.setItem("cookie_consent_miroar", JSON.stringify(consent));
         this.showFooter();
         this.settingsValue = consent;
      }
      else
      {
         console.log("The cookie consent was already given", cookieConsent);
         // localStorage.removeItem("cookie_consent_miroar");
         this.settingsValue = JSON.parse(localStorage.getItem("cookie_consent_miroar"));
      }


      console.log("Loaded settings value", this.settingsValue);


     
    }
   

    adjustSettings()
    {
      this.showConsentSettings();
      this.hideFooter();
    }

    acceptAll()
    {

      let consent = {"marketing": true, "analytics": true };
      localStorage.setItem("cookie_consent_miroar", JSON.stringify(consent));
      this.hideFooter();
    }

    closeModal()
    {
      this.hideConsentSettings();
    }

    hideFooter()
    {
      this.consentFooterTarget.classList.add("hidden");
    }

    showFooter()
    {
      this.consentFooterTarget.classList.remove("hidden");
    }

    showConsentSettings()
    { 

      //Adjust the selected to the stored settings

      let analyticsButton = document.querySelector('.option_select_indicator.analytics');
      if (!this.settingsValue["analytics"]) {
        analyticsButton.classList.remove('selected');
      }
      let marketingButton = document.querySelector('.option_select_indicator.marketing');
      if (!this.settingsValue["marketing"]) {
        marketingButton.classList.remove('selected');
      }

      this.settingsPopupTarget.classList.remove("hidden");
    }

    hideConsentSettings()
    {
      this.settingsPopupTarget.classList.add("hidden");
    }

    clickMarketingCookies(event) {
        this.adjustLocalStorageAndSelection(event, "marketing")  
    }

    clickAnalyticsCookies()
    {
       this.adjustLocalStorageAndSelection(event, "analytics")
    }

    saveSettings()
    {
      localStorage.setItem("cookie_consent_miroar", JSON.stringify(this.settingsValue));
      this.hideConsentSettings();
    }

    cancelChanges()
    {
      //Reload the old settings
      const cookieConsent = localStorage.getItem("cookie_consent_miroar");
      this.settingsValue = JSON.parse(localStorage.getItem("cookie_consent_miroar"));
      this.hideConsentSettings();
    }

    adjustLocalStorageAndSelection(event, key)
    {
       event.currentTarget.getElementsByClassName("option_select_indicator").forEach(function (select_indicator) {
            select_indicator.classList.toggle("selected");
        });

        let isSelected = false;
        event.currentTarget.getElementsByClassName("option_select_indicator").forEach(function (select_indicator) {
            if (select_indicator.classList.contains("selected")) {
                isSelected = true;
            }
        });

       
        let newSettingsDict = this.settingsValue;
        newSettingsDict[key] = isSelected;
        this.settingsValue = newSettingsDict;

       
    }
	
   
}