import { Controller } from 'stimulus';
import Rails from "@rails/ujs";



export default class extends Controller {
   

    static targets = ['staticOrder'];
  
    initialize() {
      console.log("Coupon stimulus controller");

       
        

    }

    selectMotive(event)
    {

      $(event.currentTarget).parent(".vertical_select_wrapper").children(".vertical_select_option").removeClass("selected");
      $(event.currentTarget).parent(".vertical_select_wrapper").find(".option_select_indicator").removeClass("selected");
      $(event.currentTarget).find(".option_select_indicator").addClass("selected");
      $(event.currentTarget).addClass("selected");

      $("#design_preview").attr("src",$(event.currentTarget).data("design-preview"));


      $("#static_design").val($(event.currentTarget).data("static-design"));

      console.log($(event.currentTarget).data("static-design"));

    }



    newStaticOrder(event)
    {
      console.log("New static order");
      this.staticOrderTarget.submit();
    }

    backgroundIsChanged(event)
    {
      console.log("Background is changed", $(event.currentTarget));
      var button = $(event.currentTarget).next();
      console.log("Button", button);
      $("#background_wrapper").css('cssText', 'background-color: ' + button.css("background-color") + "!important; max-height: 84%;");

    }

    





}