import { Controller } from 'stimulus';
import Rails from "@rails/ujs";
export default class extends Controller {
   
   	static values = { submitted: Boolean, checkout: String }

 


	toggleEventSelector(event)
    {
    	
    	
    	document.getElementById("left-column").classList.add("scroll_left")
    }

    removeScrollLeft()
    {
    	document.getElementById("left-column").classList.remove("scroll_left")
    }

    switchTextButton(event)
    {
    	console.log("Toggle event cause",event.target);

    	var withText = event.target.dataset.withText;
    	console.log("With text",withText);

    	if(withText == "true")
    	{
    		document.getElementById("imageBackground").classList.remove("without_text");
    		document.getElementById("landing_customer_text_preview").style.opacity = 1;
    	}
        else
        {
        	document.getElementById("imageBackground").classList.add("without_text");
        	document.getElementById("landing_customer_text_preview").style.opacity = 0;
        }

		document.querySelectorAll('.landing_text').forEach(function(button)
        {
            button.classList.remove('selected');
        });


    	event.target.classList.add("selected");
    	
    	this.removeScrollLeft();
    }

    

    switchBackgroundButton(event)
    {
    	console.log("Toggle background",event);
    	console.log("Toggle background",event.target.dataset.backgroundValue);
    	var newColor = event.target.dataset.backgroundValue;
    	var rgb = "rgb(255,255,255)"

    	if( newColor == "nude")
    	{
    		rgb = "rgb(252, 246, 242)"
    	}
    	else if (newColor == "gray")
    	{
    		rgb = "rgb(235, 235, 235)"
    	}
        else if (newColor == "beige")
        {
            rgb = "rgb(242, 230, 206)"
        }

    	
    	document.querySelectorAll('.landing_color').forEach(function(button)
        {
            button.classList.remove('selected');
        });

    	event.target.classList.add("selected");
		

		document.getElementById("imageBackground").style.backgroundColor = rgb

    	this.removeScrollLeft();

    }

    switchEventTypeButton(event)
    {
    	document.getElementById("landing_page_image").src = event.target.dataset.imageUrl;
        console.log("New url", event.target.dataset.imageUrl);
    	console.log("new title",event.target.dataset.maintitle);
    	console.log(document.getElementsByClassName("main_customer_title")[0].innerHTML);
		document.getElementsByClassName("main_customer_title")[0].innerHTML = event.target.dataset.maintitle;
		document.getElementsByClassName("main_customer_subtitle")[0].innerHTML = event.target.dataset.subtitle;
    	


    	document.querySelectorAll('.landing_event_type').forEach(function(button)
        {
            button.classList.remove('selected');
        });

    	event.target.classList.add("selected");

  		this.removeScrollLeft();
    }

   
}