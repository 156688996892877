import { Controller } from 'stimulus';
import Rails from "@rails/ujs";
export default class extends Controller {
    static targets = ['form','order'];
   	static values = { submitted: Boolean, checkout: String, currentLocale: String }

    initialize() {
	  console.log("Stimulus");
	 }
    


    submit(event)
    {
    	let isValid = this.validateForm(this.formTarget);
    	if(isValid)
    	{
    		this.formTarget.submit();
    	}
    	else
    	{
    		this.submittedValue = true
    	}
    	
    }
    formIsChanged(event)
    {
    	if(this.submittedValue)
    	{
    		this.validateForm(this.formTarget);
    	}
    }

    selectChanged(event)
    {
    	console.log("Form changed",event.target.value);

        let locale = this.getCurrentGlobalLocale();
    	Rails.ajax({
	      type: "post",
	      url: `/${locale}/checkouts/update_country`,
	      data: `checkout_token=${this.checkoutValue}&country=${event.target.value}`,
	      success: function(data) {
		    console.log(data.new_price);
		    console.log(document.getElementById("total-checkout-price"));
		    document.getElementById("total-checkout-price").textContent = data.new_price
		    document.getElementById("country-shipping-information").innerHTML = data.message 
		  },
		  error: function(data)
		  {
		  	console.log("Something went wrong!");
		  }
	    })
    }


    newOrder(event)
    {
    	console.log("Submit new order",document.getElementById("preview-picture").getAttribute('data-do-not-submit'));
    	var personError = (parseInt(document.getElementById("order_number_of_persons").value) == 0);
        console.log("NUmber of persons", personError);
        var uploadError = (document.getElementById("preview-picture").getAttribute('data-do-not-submit') == "true")
    	console.log("Upload error",uploadError);
    	console.log("src",document.getElementById("preview-picture").src != "")
    	if(document.getElementById("preview-picture").src != "" && !uploadError && !personError)
    	{
            console.log("Window.datalayer",window.dataLayer)

            //5 is the default item id
            var item_id = (window.localStorage.getItem("miroar_item") != null)  ? window.localStorage.getItem("miroar_item") : 5;

            window.dataLayer.push({
                'event': 'add_to_cart',
                'value': 70,
                'items' : [{
                    'id': item_id,
                    'google_business_vertical': 'retail'
                }]});
    	    this.orderTarget.submit();
    	}
    	else
    	{
            if(document.getElementById("preview-picture").src == "" || uploadError)
            {
                //Show error message
                document.getElementById("upload-missing-error").style.display = "block";
                //Switch to the first tab
                document.getElementById("first_tab").click();

              
                var elementPosition = document.getElementById("upload-missing-error").offsetTop;

                window.scrollTo({
                     top: elementPosition,
                     behavior: "smooth"
                });

    
            }
            if(personError)
            {
                 //Show error message
                document.getElementById("person-missing-error").style.display = "block";
                //Switch to the first tab
                document.getElementById("first_tab").click();
            }
    		
    	}

    }

    validateForm() {

    	

	    let isValid = true;


	    function validatePresence(value,input)
		{
			return !(input === "")
		};

		function validateNumeric(value,input)
		{
			console.log("Validate numeric",input);
			const re = /^[0-9]*$/
			return re.test(String(input).toLowerCase());
		};

		function validateEmail(value,input)
		{
			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    		return re.test(String(input).toLowerCase());
    	};

    	function validateCheckbox(value,input)
		{
    		return document.getElementById("checkbox-2").checked;
    	};

		var validator = {"validatePresence":[validatePresence, this.getTranslatedMessageForKey("validate_presence",null)]}

		validator["numeric"] = [validateNumeric,this.getTranslatedMessageForKey("validate_numeric",null)]
		validator["email"] = [validateEmail,this.getTranslatedMessageForKey("validate_email",null)]
		validator["checked"] = [validateCheckbox,this.getTranslatedMessageForKey("validate_checkbox",null)]
	    

	    let rules =  {
                    "checkout[user_attributes][first_name]": {
                    	"validatePresence": true,
                    },
                    "checkout[user_attributes][last_name]": {
                    	"validatePresence": true,
                    },
                    "checkout[user_attributes][email]": {
                    	"validatePresence": true,
                    	"email": true
                    },
                    "checkout[user_attributes][phone_number]": {
                    	"validatePresence": true,
                    },
                    "checkout[user_attributes][street]": {
                    	"validatePresence": true,
                    },
                    "checkout[user_attributes][housenumber]": {
                    	"validatePresence": true,
                    },
                    "checkout[user_attributes][zip_code]": {
                    	"validatePresence": true,
                    },
                    "checkout[user_attributes][city]": {
                    	"validatePresence": true,
                    },
                    "checkbox-2": {
                    	"checked": true,
                    }
        }

        var validation_result = true
        for (var key in rules) {
		    console.log("Key",key)
		    let field = this.formTarget.querySelector(`input[name='${key}']`);
		    console.log("Current input",field);
		    console.log("Current input",);
		    var current_value = field.value.trim()
		    var validations = rules[key]
		    for (key in validations)
		    {
		    	console.log("Validation keys",key);
		    	console.log("Validation value",validations[key]);
		    	var validation_function = validator[key][0];
		    	console.log("Problem",validation_function(validations[key],current_value));
		    	if(!validation_function(validations[key],current_value))
		    	{
		    		console.log("PRint message",validator[key][1])
		    		validation_result = false;
		    		var node = field.parentNode.querySelector(".label_error")
		    		
		    		if(node != null)
		    		{
		    			node.textContent = validator[key][1]
		    		}
		    		else
		    		{
		    			document.getElementById("checkbox-label").style.color = "red";
		    		}
		    		break;
		    	}
		    	else
		    	{	
		    		var node = field.parentNode.querySelector(".label_error")
		    		
		    		if(node != null)
		    		{
		    			node.textContent = ""
		    		}
		    		else
		    		{
		    			document.getElementById("checkbox-label").style.color = "black";
		    		}
		    	}
		    }
		    console.log("Test",field.parentNode.querySelector(".error_label"));
		    console.log("Test",field.closest( ".error_label" ));
		    

		}

		return validation_result;

	    // // Tell the browser to find any required fields
	    // let requiredFieldSelectors = 'textarea:required, input:required';
	    // let requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors);

	    // requiredFields.forEach((field) => {
	    //   // For each required field, check to see if the value is empty
	    //   // if so, we focus the field and set our value to false
	    //   if (!field.disabled && !field.value.trim()) {
	    //     field.focus();

	    //     isValid = false;
	    //   }
	    // });

	    return isValid;
	}


	//Editor functionality
	headlineChanged(event)
    {	
    	

    	if(event.target.value != "")
    	{
    		console.log("Show text preview");
    		document.getElementById("imageBackground").classList.remove('without_text');
    		document.getElementById("text_preview").classList.remove('hidden');
    	}
        else
        {
        	console.log("Hide text preview");
        	document.getElementById("imageBackground").classList.add('without_text');
        	document.getElementById("text_preview").classList.add('hidden');
        }

        var length = 35 - event.target.value.length;
        if(length >= 0)
        {
            document.getElementById("main_title_chars").innerHTML = this.getTranslatedMessageForKey("characters_remaining",length);

            if(length==0)
            {
                document.getElementById("main_title_chars").classList.add("reached");
            }
            else
            {
                document.getElementById("main_title_chars").classList.remove("reached");
            }

        }
        else
        {
            document.getElementById("main_title_chars").classList.add("reached");
            event.target.value = event.target.value.slice(0,35);
        }

        document.getElementById("main_customer_title").innerHTML = event.target.value;

    }

    //Editor functionality
	subtitleChanged(event)
    {	
    	
        var length = 35 - event.target.value.length;
        if(length >= 0)
        {
            document.getElementById("sub_title_chars").innerHTML = this.getTranslatedMessageForKey("characters_remaining",length);

            if(length==0)
            {
                document.getElementById("sub_title_chars").classList.add("reached");
            }
            else
            {
                document.getElementById("sub_title_chars").classList.remove("reached");
            }

        }
        else
        {
            document.getElementById("sub_title_chars").classList.add("reached");
            event.target.value = event.target.value.slice(0,35);
        }

        document.getElementById("main_customer_subtitle").innerHTML = event.target.value;

    }


    openDropdown(event)
    {
    	console.log("OPen dropdown",this);
    	this.element.querySelector('.custom-select').classList.toggle('open');
    }
    changeFont(event)
    {
    	console.log("Change font",this);
    	console.log("Change event",event.target.classList);
    	if (!event.target.classList.contains('selected')) {
               document.querySelectorAll('.font_selection_button').forEach(function(button)
                {
                    button.classList.remove('selected');
                });
               event.target.classList.add('selected');
            console.log(event.target.getAttribute("data-value-font"));
            document.getElementById("main_customer_title").style.fontFamily = event.target.getAttribute("data-value-font");
            document.getElementById("main_customer_subtitle").style.fontFamily = event.target.getAttribute("data-value-font");
            document.getElementById("main_customer_subtitle").style.fontWeight = event.target.getAttribute("data-value-weight");
            document.getElementById("font_family").value = event.target.getAttribute("data-value-font");
        }
    }

    getTranslatedMessageForKey(key,message_parameter)
    {
      var messages = {};
      var fr_dict = {"validate_presence": "Ce champ est requis.", "validate_numeric": "La saisie ne doit contenir que des chiffres.", "validate_email": "S'il vous plaît entrer une adresse e-mail valide.", "validate_checkbox": "Vous devez accepter les conditions générales.", "characters_remaining": "$variable/35 caractères restant"  };
      var de_dict = {"validate_presence": "Dieses Feld wird benötigt.", "validate_numeric": "Die Eingabe darf nur aus Zahlen bestehen.", "validate_email": "Bitte gib eine gültige E-Mail ein.", "validate_checkbox": "Du musst unsere AGB bestätigen.", "characters_remaining": "Noch $variable von 35 Zeichen verbleibend"  };
      var nl_dict = { "validate_presence": "Dit veld is verplicht.","validate_numeric": "Invoer mag alleen uit cijfers bestaan.","validate_email": "Voer een geldig e-mailadres in.","validate_checkbox": "Je moet onze algemene voorwaarden accepteren.", "characters_remaining": "Nog $variable van 35 tekens over"};
      var es_dict = { "validate_presence": "Este campo es obligatorio.", "validate_numeric": "La entrada solo debe contener números.", "validate_email": "Por favor, introduce una dirección de correo electrónico válida.", "validate_checkbox": "Debes aceptar nuestros términos y condiciones.", "characters_remaining": "Quedan $variable de 35 caracteres"}; 
      var it_dict = {"validate_presence": "Questo campo è obbligatorio.", "validate_numeric": "L'input deve contenere solo numeri.","validate_email": "Inserisci un indirizzo email valido.", "validate_checkbox": "Devi accettare i nostri Termini e Condizioni.","characters_remaining": "$variable caratteri rimanenti su 35"};
      var ie_dict = {"validate_presence": "This field is required.","validate_numeric": "Input must consist of numbers only.", "validate_email": "Please enter a valid email address.", "validate_checkbox": "You must accept our terms and conditions.", "characters_remaining": "$variable out of 35 characters remaining" };
      var se_dict = {"validate_presence": "Detta fält är obligatoriskt.","validate_numeric": "Inmatningen får endast bestå av siffror.","validate_email": "Ange en giltig e-postadress.","validate_checkbox": "Du måste godkänna våra villkor och bestämmelser.","characters_remaining": "Återstående $variable av 35 tecken"};
      var dk_dict = { "validate_presence": "Dette felt er påkrævet.", "validate_numeric": "Indtastning skal bestå af tal kun.", "validate_email": "Indtast en gyldig e-mailadresse.", "validate_checkbox": "Du skal acceptere vores vilkår og betingelser.", "characters_remaining": "Endnu $variable af 35 tegn tilbage" };
      var pl_dict = {"validate_presence": "To pole jest wymagane.","validate_numeric": "Wprowadź tylko cyfry.", "validate_email": "Wprowadź poprawny adres e-mail.","validate_checkbox": "Musisz zaakceptować nasze warunki i zgody.", "characters_remaining": "Pozostało $variable z 35 znaków" };
      var fi_dict = { "validate_presence": "Tämä kenttä on pakollinen.", "validate_numeric": "Syötteen tulee sisältää vain numeroita.", "validate_email": "Anna kelvollinen sähköpostiosoite.", "validate_checkbox": "Sinun on hyväksyttävä käyttöehdot.", "characters_remaining": "Jäljellä $variable merkkiä 35:stä" };




      messages["fr"] = fr_dict;
      messages["de"] = de_dict;
      messages["nl"] = nl_dict;
      messages["es"] = es_dict;
      messages["ie"] = ie_dict;
      messages["com"] = ie_dict;
      messages["uk"] = ie_dict;
      messages["it"] = it_dict;
      messages["se"] = se_dict;
      messages["dk"] = dk_dict;
      messages["pl"] = pl_dict;
      messages["fi"] = fi_dict; 


      var currentLocale = this.currentLocaleValue;

      if(currentLocale.indexOf("ch") != -1)
      { 
        currentLocale = currentLocale.split("-")[1];
      }

      if(currentLocale == "at")
      {
        currentLocale = "de"
      }

      if(currentLocale.indexOf("be") != -1)
      { 
        currentLocale = currentLocale.split("-")[1];
      }

      //No message parameter means that there does not need to be a variable inserted
      if(message_parameter == null)
      {
      	return messages[currentLocale][key]
      }
  	  else
  	  {
  	  	return messages[currentLocale][key].replace("$variable",message_parameter)
  	  }

      

    }

    getCurrentGlobalLocale()
    {
      return document.head.querySelector("[name='global_current_locale']").content;
    }



}