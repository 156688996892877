import { Controller } from 'stimulus';
import Rails from "@rails/ujs";



export default class extends Controller {
   
   static targets = ["modal","currentPrintNumberSelection","printNumberSelection"]

   // token is the checkout token and order is the order token
   static values = { token: String, order: String }
   
    initialize() {
      console.log("After Checkout stimulus controller");

    }


    selectSmartphoneCase()
    {
      console.log("Select smartphone case");


      this.modalTarget.classList.remove("hidden");

      Rails.ajax({
      type: "get",
      url: `/display_smartphone_modal`,
      data: `checkout_token=${this.tokenValue}&token=${this.orderValue}`,
      success: function(result) { 
          document.getElementById("modalContent").innerHTML = result.modal_content;
      }.bind(this),
      error: function(data)
      {
        console.log("Something went wrong!");
      }
      });


    }


    selectDigitalFile()
    {
      console.log("Select digital file");


      let params = `order[size]=digital_file&additional_order_type=digital_file&main_order_token=${this.orderValue}&checkout_token=${this.tokenValue}`

      Rails.ajax({
        type: "POST",
        url: `/orders/create_additional_order`,
        data: params,
        success: function(result) { 
            document.getElementById("modalContent").innerHTML = result.modal_content;
        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      });
    }


    selectFrame()
    {
      console.log("Select frame")
      this.modalTarget.classList.remove("hidden");

      Rails.ajax({
        type: "get",
        url: `/display_frame_selection_modal`,
        data: `checkout_token=${this.tokenValue}&token=${this.orderValue}`,
        success: function(result) { 
            document.getElementById("modalContent").innerHTML = result.modal_content;
        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      });
    }

    frameSelectionChanged()
    {
      console.log("Frame selection changed")
      document.getElementById("order_frame").value = event.currentTarget.dataset.size
      this.submitFrameOrder();
    }

    closeModal()
    {
       this.modalTarget.classList.add("hidden");
       document.getElementById("modalContent").innerHTML = "";
    }


    smartphoneColorChange(event)
    {
      $(event.target).parent().parent().find(".selection").removeClass("selected");
      var button = $(event.target).next();
      button.addClass("selected");
      this.submitSmartphoneOrder();
    }

    smartphoneSizeChange(event)
    {
      document.getElementById("order_size").value = event.currentTarget.dataset.size
      this.submitSmartphoneOrder();
    }


    // This method adds multipe prints to the after checkout flow 
    selectAdditionalPrints()
    {
      console.log("Select additional prints");


      let params = `order[additional_information[additional_order_type]]=additional_prints&order[additional_information[additional_prints]]=1&additional_order_type=additional_prints&main_order_token=${this.orderValue}&checkout_token=${this.tokenValue}`

      Rails.ajax({
        type: "POST",
        url: `/orders/create_additional_order`,
        data: params,
        success: function(result) { 
            document.getElementById("modalContent").innerHTML = result.modal_content;
        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      });


    }

    // This method adds a cup to the after checkout flow 
    selectAdditionalCup()
    {
      console.log("Select additional cup");


      let params = `order[additional_information[additional_order_type]]=cup&additional_order_type=cup&main_order_token=${this.orderValue}&checkout_token=${this.tokenValue}`

      Rails.ajax({
        type: "POST",
        url: `/orders/create_additional_order`,
        data: params,
        success: function(result) { 
            document.getElementById("modalContent").innerHTML = result.modal_content;
        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      });


    }

    showPrintNumberSelection()
    {
       this.printNumberSelectionTarget.style.display = "block";
    }

    changePrintNumber(event)
    {
      this.currentPrintNumberSelectionTarget.innerHTML = $(event.currentTarget).find(".frame_select_heading")[0].innerHTML;
      //Update the selection
      $(event.currentTarget).parent().children(".frame_select_row").removeClass("frame_selected");
      $(event.currentTarget).addClass("frame_selected");
      var newPrintNumberValue = $(event.currentTarget).find(".frame_select_heading").data("print-number-value")
      $('#order_number_of_prints').val(newPrintNumberValue);

      this.printNumberSelectionTarget.style.display = "none";

      let data = `order[additional_information[additional_order_type]]=additional_prints&order[additional_information[additional_prints]]=${newPrintNumberValue}`

      let orderToken = this.printNumberSelectionTarget.dataset.orderToken;

      console.log("Order token",orderToken);

      Rails.ajax({
        type: "patch",
        url: `/update_additional_order/${orderToken}`,
        data: data,
        success: function(result) { 
            window.location.reload();
        }.bind(this),
        error: function(data)
        {
          
        }
      });

    }


    submitSmartphoneOrder()
    { 
      if(document.getElementById("order_size").value != "")
      {
        console.log("Submit the smartphone order now");
        var elem = document.getElementById("addition_order_form")
        Rails.fire(elem, 'submit');
      }
    }

    submitFrameOrder()
    { 
      if(document.getElementById("order_frame").value != "")
      {
        var elem = document.getElementById("addition_order_form")
        Rails.fire(elem, 'submit');
      }
    }

    removeAdditionalOrder()
    {
      console.log("Remove Smartphone Case", event.currentTarget.dataset.orderToken);

      let orderToken = event.currentTarget.dataset.orderToken

      Rails.ajax({
        type: "delete",
        url: `/remove_additional_order`,
        data: `token=${orderToken}`,
        success: function(result) { 
            window.location.reload();
        }.bind(this),
        error: function(data)
        {
          
        }
      });


    }

    finalAdressConfirmation()
    {
      console.log("Final Adress confirmation");
      var elem = document.getElementById("address_form")
      Rails.fire(elem, 'submit');
     
    }


    selectRefundHeight(event)
    {
      console.log("Select refund height");
      console.log(event.currentTarget.dataset.refundValue);
      console.log($(event.currentTarget).find(".option_select_indicator"));
      $(event.currentTarget).parent(".custom_container").find(".option_select_indicator").removeClass("selected");
      $(event.currentTarget).find(".option_select_indicator").addClass("selected");

      $("#refund_height").val(event.currentTarget.dataset.refundValue);    
    }

    confirmCheckoutCancelation(event)
    {
      
      var elem = document.getElementById("cancel_form")
      Rails.fire(elem, 'submit');

    }




  


  
   
}