import { Controller } from 'stimulus';
import Rails from "@rails/ujs";


export default class extends Controller {
    

    static values = { currentLanguage: String }

    static targets = ["navbar"];
   
    initialize() {
      console.log("Language stimulus controller");

     

    }

    closeSelector()
    {
      document.getElementById("language_selector").classList.add("hidden");
    }

    selectLanguage(event)
    {
      console.log("Select language", event.currentTarget.dataset.languageLocale);

      document.getElementsByClassName("option_select_indicator").forEach(function (select_indicator) {
        select_indicator.classList.remove("selected");
      });
      event.currentTarget.firstElementChild.firstElementChild.classList.add("selected");

      this.currentLanguageValue = event.currentTarget.dataset.languageLocale;

      console.log("event.currentTarget.dataset.languageLocale",event.currentTarget.dataset);

      console.log(JSON.parse(event.currentTarget.dataset.languageTextData));
      let languageDict = JSON.parse(event.currentTarget.dataset.languageTextData);
      console.log("Language text",languageDict)
      document.getElementsByClassName("dynamic_replace_text").forEach(function(replacementNode){
        console.log("Replacement text",languageDict[replacementNode.dataset.key]);
        replacementNode.innerHTML = languageDict[replacementNode.dataset.key];
      })

    }

    confirmLanguage()
    {
      console.log("Confirm language");


      Rails.ajax({
        type: "post",
        url: "/change_language",
        data: `target_language=${this.currentLanguageValue}&current_url=${window.location.href}`,
        success: function(new_url) { 
          window.location = new_url;
          this.closeSelector();

        }.bind(this),
        error: function(data)
        {
          console.log("Could not change the language");
          this.closeSelector();
        }.bind(this)
      })

    }

    
}