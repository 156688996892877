import { Controller } from 'stimulus';
import Rails from "@rails/ujs";



export default class extends Controller {
   
   	static values = { submitted: Boolean, checkout: String }

    static targets = ["pageView"]
 
    initialize() {
      console.log("Scroll stimulus controller");

       this.isScrolling = null;
     

    }

    onScroll(event){
      let currentWidth = event.currentTarget.clientWidth
      let page = Math.round(event.currentTarget.scrollLeft/currentWidth) + 1;

      this.updatePageIndicator(page);


      window.clearTimeout( this.isScrolling );

      // Set a timeout to run after scrolling ends
      this.isScrolling = setTimeout(function() {

          console.log("Stop scrolling");
          console.log("Current width", currentWidth);
          console.log("Current page", page);
          let targetScrollPosition = (page-1) * currentWidth;
          console.log("Target position", targetScrollPosition);
          console.log("Test",document.querySelector(".image_scroll_view"));
          document.querySelector(".image_scroll_view").scrollTo({
               left: targetScrollPosition,
               behavior: "smooth"
          });

      }, 66);

    }


    updatePageIndicator(page)
    {
      this.pageViewTarget.querySelector(".page_view_dot.selected").classList.remove("selected");
      this.pageViewTarget.querySelector(`.page_view_dot:nth-child(${page})`).classList.add("selected")
    }

  
    
}