import { Controller } from 'stimulus';
import Rails from "@rails/ujs";


export default class extends Controller {
    

    static values = { fixed: Boolean, scrollDirection: String, }

    static targets = ["navbar"];
   
    initialize() {
      console.log("Navbar stimulus controller");

      console.log("This.fixed", this.fixedValue);

      this.boundUserDidScroll = this.userDidScroll.bind(this);

      window.addEventListener('scroll', this.boundUserDidScroll);

      this.scrollDirectionValue = 0
      this.minThreshold = -90

    }

    disconnect()
    {
      window.removeEventListener('scroll', this.boundUserDidScroll);
    }

    userDidScroll()
    { 

      //in case the scrollbar is fixed, do not hide it on scroll
      if(this.fixedValue)
      {
        return; 
      }


      if ((document.body.getBoundingClientRect()).top < this.scrollDirectionValue && (document.body.getBoundingClientRect()).top < this.minThreshold)
      {
         this.navbarTarget.classList.add("navbar_hidden");
      }
      else
      {
        this.navbarTarget.classList.remove("navbar_hidden");
      }

      // saves the new position for iteration.
      this.scrollDirectionValue = (document.body.getBoundingClientRect()).top;

    }

    showLanguageSelector(event)
    {
      console.log("Open language selector");
      document.getElementById("language_selector").classList.remove("hidden");
    }

    
}