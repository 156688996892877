// ./controllers/flatpickr_controller.js
// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from 'stimulus-flatpickr'
import Rails from "@rails/ujs";
// you can also import a translation file
import { German } from 'flatpickr/dist/l10n/de.js'

import { French } from 'flatpickr/dist/l10n/fr.js'

import { Spanish } from 'flatpickr/dist/l10n/es.js'

import { Italian } from 'flatpickr/dist/l10n/it.js'
import { Dutch } from 'flatpickr/dist/l10n/nl.js'
import { Swedish } from 'flatpickr/dist/l10n/sk.js'
import { Danish } from 'flatpickr/dist/l10n/da.js'
import { Polish } from 'flatpickr/dist/l10n/pl.js'

// import a theme (could be in your main CSS entry too...)
import 'flatpickr/dist/themes/dark.css'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {


  static values = { token: String, currentLocale: String }
    

  initialize() {


    var currentLocale =  this.currentLocaleValue;

    console.log("This currentLocaleValue", this.currentLocaleValue);

    if(this.currentLocaleValue == "de" || this.currentLocaleValue == "at" || this.currentLocaleValue == "ch-de")
    {
      currentLocale = German
    }
    else if(this.currentLocaleValue == "fr" || this.currentLocaleValue == "be-fr" || this.currentLocaleValue == "ch-fr")
    {
      currentLocale = French
    }
    else if(this.currentLocaleValue == "es")
    {
      currentLocale = Spanish
    }
    else if(this.currentLocaleValue == "it" || this.currentLocaleValue == "ch-it")
    {
      currentLocale = Italian
    }
    else if(this.currentLocaleValue == "ie" || this.currentLocaleValue == "co-uk")
    {
      //Use the default english language
      currentLocale = null;
    }
    else if(this.currentLocaleValue == "nl" || this.currentLocaleValue == "be-nl")
    {
      currentLocale = Dutch
    }
    else if(this.currentLocaleValue == "se")
    {
      //Use the default english language, since swedish is not available
      currentLocale = null;
    }
    else if(this.currentLocaleValue == "dk")
    {
      currentLocale = Danish
    }
    else if(this.currentLocaleValue == "pl")
    {
      currentLocale = Polish
    }

    this.config = {
      locale: currentLocale,
      dateFormat: "d.m.Y", 
      disableMobile: "true", 
      enable: [
        function(date) {
            const today = new Date()
            const tomorrow = new Date(today)
            tomorrow.setDate(tomorrow.getDate() + 1)
            tomorrow.setUTCHours(-2,0,0,0);
            console.log(date);
            console.log(tomorrow);
            return (date >= tomorrow);
        }
      ]
    };
   
  }


  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change(selectedDates, dateStr, instance) {
    console.log('the callback returns the selected dates', selectedDates);
    console.log('but returns it also as a string', dateStr);
    console.log('and the flatpickr instance', instance);
    console.log('and the flatpickr instance', this.tokenValue);

    let locale = this.getCurrentGlobalLocale();

    Rails.ajax({
        type: "post",
        url: `/${locale}/user_change_max_delivery_date`,
        data: `checkout_token=${this.tokenValue}&max_delivery_date=${selectedDates[0]}`,
        success: function(result) { 
          console.log("REsult",result)
           $(".handling_options").first().replaceWith(result.handling_options);
           $(".handling_options").removeClass("hidden");
        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      });


    
  }

  //Get the current global language locale
  getCurrentGlobalLocale()
  {
    return document.head.querySelector("[name='global_current_locale']").content; 
  }

  
}